<template>
  <div class="advantages-section">
    <h2>Les Avantages de MiceTracking</h2>
    <div class="advantages">
      <div class="advantage" v-for="(advantage, index) in advantages" :key="index" :data-aos="'fade-up'" :data-aos-delay="index * 100">
        <img :src="advantage.icon" :alt="advantage.alt" />
        <h3>{{ advantage.title }}</h3>
        <p>{{ advantage.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      advantages: [
        {
            icon: require('../assets/illustrations/SurveillanceContinue.png'),
            alt: 'Montre connectée',
            title: 'Surveillance Continue et Précise',
            description: "Suivi en temps réel des paramètres biologiques et physiques de l'animal, avec des mesures continues fidèles à la milliseconde près."
        },
        {
            icon: require('../assets/illustrations/AnalyseSanté.png'),
            alt: 'Stéthoscope',
            title: 'Analyse Complète de la Santé',
            description: "Mesure de multiples paramètres vitaux tels que la température, la fréquence cardiaque, l'activité, offrant une vue détaillée de la santé."
        },
        {
            icon:  require('../assets/illustrations/BienEtre.png'),
            alt: 'Cœur avec des feuilles',
            title: 'Amélioration du Bien-être',
            description: "Observation précise sans contrainte physique, réduisant ainsi le stress et la douleur pour les animaux de laboratoire."
        },
        {
            icon: require('../assets/illustrations/AnalyseCloud.png'),
            alt: 'Nuage avec flèches',
            title: 'Gestion et Analyse via Cloud',
            description: "Les données collectées sont stockées dans le Cloud MiceCloud, facilitant la gestion centralisée des informations."
        },
        {
            icon:  require('../assets/illustrations/CageIntelligente.png'),
            alt: 'Écosystème',
            title: 'Intégration des Données Environnementales',
            description: "Les capteurs placés dans la cage collectent des données environnementales pour une vue complète des facteurs influençant le bien-être animal."
        }
      ]
    };
  },
};
</script>

<style scoped>
.advantages-section {
  text-align: center;
  margin: 50px 0;
  padding: 20px;
  border-radius: 15px;
}

.advantages-section h2{
  margin-bottom: 50px;
}
.advantages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
}
.advantage {
  width: 300px;
  padding: 20px;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.advantage:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}
.advantage img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}
.advantage h3 {
  font-weight: 600;
  font-size: 24px;
  color: var(--bleu);
  margin-bottom: 15px;
}
.advantage p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--noirdoux);
}
@media (max-width: 768px) {
  .advantages {
    flex-direction: column;
    align-items: center;
  }
}
</style>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css" />