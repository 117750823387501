<template>
    <div>
        <nav-bar/>
        <accueil-image/>
        <bulle-contact/>
        <accueil-presentation class=" padding-resp mt100"/>
        <accueil-fonctionnement class="padding-resp-2 mt100"/>
        <accueil-benefices class="padding-resp mt100"/>
        <avantages class="padding-resp mt100"/>
        <foot class="mt100"/>
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';
import AccueilImage from '../components/AccueilImage.vue';
import AccueilPresentation from '../components/AccueilPresentation.vue';
import AccueilBenefices from '../components/AccueilBenefices.vue';
import AccueilFonctionnement from '../components/AccueilFonctionnement.vue';
import foot from '@/components/Footer.vue'
import avantages from '../components/avantages.vue';
import BannerContact from '../components/banner-contact.vue';
import BulleContact from '../components/BulleContact.vue';

export default{
    components: {
        NavBar,
        AccueilPresentation,
        AccueilBenefices,
        AccueilFonctionnement,
        AccueilImage,
        avantages,
        foot,
        BannerContact,
        BulleContact,
    }
}
</script>